
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.el-breadcrumb .el-breadcrumb__item .el-breadcrumb__inner{
        color: #9A9A9A;
        font-size: 14px;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.el-button--primary{
        background-color: #FD4446!important;
    }
    .StorePromotion{
        .StorePromotionHeader{
            /*display: flex;*/
            /*justify-content: space-around;*/
            .headerTitle{
                display: flex;
                justify-content: space-between;
                p{
                    color: #333333;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1;
                    margin: 20px 0 0 0;
                }
                .btn{
                    width: 100px;
                    height: 36px;
                    border-color: #FD4446;
                    line-height: 0;
                    background: #F7F9FB;
                    color: #FD4446;
                }
            }
        }
        .StorePromotionMiddle{
            padding: 20px;
            margin-top: 18px;
            background: #ffffff;
            .StorePromotionMiddleHeader{
                display: flex;
                .newActivities{
                    display: flex;
                    flex-direction: column;
                    span{
                        color: #343434;
                        font-size: 14px;
                        line-height: 1;
                    }
                    .activitiesBox{
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 10px;
                        width: 136px;
                        height: 72px;
                        border-radius: 4px;
                        text-align: center;
                        span{
                            font-size: 12px;
                            color: #FFFFFF;
                            margin-top: 28px;
                        }
                    }
                }
                .newActivities:nth-child(1){
                    .activitiesBox{
                        background: url("./../../../../assets/images/newActivities.png") no-repeat;
                    }
                }
                .newActivities:nth-child(2){
                    margin-left: 60px;
                    .activitiesBox{
                        background: url("./../../../../assets/images/newActivities2.png") no-repeat;
                    }
                }
                .newActivities:nth-child(3){
                    margin-top: 14px;
                    margin-left: 20px;
                    .activitiesBox{
                        background: url("./../../../../assets/images/newActivities3.png") no-repeat;
                    }
                }
            }
        }
    }
